export const providers = {
  destinations: [
    {
      type: 'destination',
      title: 'Shotstack Hosting',
      description: 'All videos are saved to the Shotstack hosting and CDN service.',
      slug: 'shotstack',
      provider: 'hosting',
      image: 'shotstack.png',
      status: 'active',
      paid: false,
    },
    {
      type: 'destination',
      title: 'Mux Video',
      description: 'Transfer videos to the Mux video hosting and streaming service.',
      slug: 'mux',
      provider: 'mux',
      image: 'mux.png',
      status: 'inactive',
      paid: true,
      apiKeys: true,
    },
    {
      type: 'destination',
      title: 'AWS S3',
      description: 'Transfer files to one or more S3 buckets in the same AWS account.',
      slug: 's3',
      provider: 's3',
      image: 'aws.png',
      status: 'active',
      paid: true,
      apiKeys: true,
    },
    {
      type: 'destination',
      title: 'Google Cloud Storage',
      description: 'Transfer files to your Google Cloud Storage.',
      slug: 'google-cloud-storage',
      provider: 'google-cloud-storage',
      image: 'gcp.png',
      status: 'active',
      paid: true,
      apiKeys: true,
    },
    {
      type: 'destination',
      title: 'Google Drive',
      description: 'Transfer files to your Google Drive.',
      slug: 'google-drive',
      provider: 'google-drive',
      image: 'gdrive.png',
      status: 'active',
      paid: true,
      apiKeys: true,
    },
    {
      type: 'destination',
      title: 'Vimeo',
      description: 'Transfer files to your Vimeo account.',
      slug: 'vimeo',
      provider: 'vimeo',
      image: 'vimeo.png',
      status: 'active',
      paid: true,
      apiKeys: true,
    },
    {
      type: 'destination',
      title: 'TikTok',
      description: 'Upload videos to your TikTok.',
      slug: 'tiktok',
      provider: 'tiktok',
      image: 'tiktok.png',
      status: 'active',
      apiKeys: true,
    },
    {
      type: 'destination',
      title: 'YouTube',
      description: 'Upload files to your YouTube account.',
      slug: 'youtube',
      provider: 'youtube',
      image: 'youtube.png',
      status: 'pending',
      paid: true,
      apiKeys: true,
    },
  ],
  ai: [
    {
      type: 'ai',
      title: 'Shotstack Text-to-Speech',
      description:
        "Automatically generate voice overs in over 30 languages with Shotstack's text-to-speech technology.",
      slug: 'shotstack',
      provider: 'text-to-speech',
      image: 'shotstack.png',
      status: 'inactive',
      paid: true,
    },
    {
      type: 'ingest',
      title: 'OpenAI',
      description: 'Generate text with OpenAI.',
      slug: 'openai',
      provider: 'openai',
      image: 'openai.png',
      status: 'inactive',
      paid: true,
    },
    {
      type: 'ai',
      title: 'ElevenLabs Text-to-Speech',
      description: 'Integrate with your ElevenLabs text-to-speech account.',
      slug: 'elevenlabs',
      provider: 'elevenlabs',
      image: 'elevenlabs.png',
      status: 'active',
      alpha: true,
      paid: true,
      apiKeys: true,
    },
    {
      type: 'ai',
      title: 'D-ID Text-to-Avatar',
      description: 'Integrate your D-ID account to add avatars to your videos or applications.',
      slug: 'd-id',
      provider: 'd-id',
      image: 'd-id.png',
      status: 'inactive',
      alpha: true,
      paid: true,
      apiKeys: true,
    },
    {
      type: 'ai',
      title: 'HeyGen Text-to-Avatar',
      description:
        'Integrate your HeyGen account to bring text-to-avatars functionality to your video creation process with Shotstack.',
      slug: 'heygen',
      provider: 'heygen',
      image: 'heygen.png',
      status: 'inactive',
      alpha: true,
      paid: true,
      apiKeys: true,
    },
    {
      type: 'ai',
      title: 'Stability Text-to-Image',
      description:
        'Integrate your Stability account to bring text-to-image functionality to your video creation process with Shotstack.',
      slug: 'stability-ai',
      provider: 'stability-ai',
      image: 'stabilityai.png',
      status: 'inactive',
      alpha: true,
      paid: true,
      apiKeys: true,
    },
    {
      type: 'ingest',
      title: 'Dolby.io',
      description: 'Enhance your media with Dolby.io.',
      slug: 'dolby',
      provider: 'dolby',
      image: 'dolby.png',
      status: 'active',
      paid: true,
    },
  ],
  nocode: [
    {
      type: 'nocode',
      title: 'Make (formerly Integromat)',
      description: 'Use Make to automate your media generation.',
      slug: 'make',
      provider: 'make',
      image: 'make.png',
      status: 'active',
      paid: false,
    },
    {
      type: 'nocode',
      title: 'Zapier',
      description: 'Use Zapier to automate your media generation.',
      slug: 'zapier',
      provider: 'zapier',
      image: 'zapier.png',
      status: 'active',
      paid: false,
    },
    {
      type: 'nocode',
      title: 'Studio SDK',
      description: 'Use the Shotstack Studio SDK to integrate editing capabilities into your own application.',
      slug: 'studio',
      provider: 'studio',
      image: 'shotstack.png',
      status: 'inactive',
      paid: false,
    },
  ],
  ingest: [
    {
      type: 'ingest',
      title: 'After Effects',
      description: 'Ingest your After Effects compositions.',
      slug: 'afterEffects',
      provider: 'afterEffects',
      image: 'after-effects.png',
      status: 'inactive',
      alpha: true,
      paid: false,
    },
  ],
  tools: [
    {
      type: 'tools',
      title: 'Video to MP3',
      description: 'Convert videos to MP3 audio.',
      slug: 'video-to-mp3',
      provider: 'video-to-mp3',
      image: 'shotstack.png',
      status: 'active',
      paid: false,
    },
  ],
};

export default Object.values(providers).flat();

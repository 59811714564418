import { useRecoilState } from 'recoil';

import { IconScale } from '@assets/icons';

import InputNumber from '@components/controls/InputNumber';

import { thumbnailAtomFamily } from '@store/Output';

import { parseNumberOrEmptyString } from '@utils/numbers';

function ThumbnailScaleInput({}) {
  const [scale, setScale] = useRecoilState(thumbnailAtomFamily('scale'));

  const handleChange = (event) => {
    const { value } = event.target;
    setScale(parseNumberOrEmptyString(value) / 100);
  };

  return (
    <InputNumber
      id="thumbnail-scale"
      title="Thumbnail Scale"
      value={scale ?? '' ? scale * 100 : ''}
      min={0}
      max={100}
      prefix={<IconScale size={18} />}
      step={1}
      handleChange={handleChange}
      suffix="%"
    />
  );
}

export default ThumbnailScaleInput;

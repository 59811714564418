import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconFit, IconScale } from '@assets/icons';

import InputNumber from '@components/controls/InputNumber';
import InputSelect from '@components/controls/InputSelect';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';
import { clipKeyframesPropertiesEnabledSelectorFamily } from '@store/Keyframes';

import Fit from '@constants/Fit';

function SizeFields({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const propertiesKeyframed = useRecoilValue(clipKeyframesPropertiesEnabledSelectorFamily(id));

  const handleFitChange = (event) => {
    const { value } = event.target;

    if (value.length > 0) {
      setClip({ fit: value });
    } else {
      setClip({ fit: undefined });
    }
  };

  const handleScaleChange = (event) => {
    const { value } = event.target;

    if (value.length > 0 && value >= 0) {
      setClip({ scale: parseFloat(value) / 100 });
    } else {
      setClip({ scale: undefined });
    }
  };

  const isScaleAnimated = useMemo(() => propertiesKeyframed.includes('scale'), [propertiesKeyframed]);

  return (
    <Row>
      <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
        <InputSelect
          id="fit"
          title="Fit"
          selected={clip?.fit || 'crop'}
          values={Fit}
          prefix={<IconFit size={18} />}
          handleChange={handleFitChange}
        />
        <OverrideMenu path="fit" />
      </Col>
      <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
        <InputNumber
          id="scale"
          title={`Scale${isScaleAnimated ? ' (Animated)' : ''}`}
          value={clip?.scale >= 0 ? clip?.scale * 100 : ''}
          placeholder="1"
          min={0}
          max={400}
          disabled={isScaleAnimated}
          prefix={<IconScale size={18} />}
          step={1}
          suffix="%"
          handleChange={handleScaleChange}
        />
        <OverrideMenu path="scale" isAnimated={isScaleAnimated} />
      </Col>
    </Row>
  );
}

export default SizeFields;

import { Scrollbar } from 'react-scrollbars-custom';

import PanelMediaListing from '@feature/studio/panel/PanelMediaListing';
import PanelOverlayUpload from '@feature/studio/panel/PanelOverlayUpload';

function PanelOverlayDefault() {
  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <PanelOverlayUpload />
        <PanelMediaListing type="overlay" />
      </Scrollbar>
    </div>
  );
}

export default PanelOverlayDefault;

import { Col, Row } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import OutputPosterCaptureInput from '@feature/studio/setting/output/PosterCaptureInput';

import { templateIdAtom } from '@store/Template';

function SettingPosterFields() {
  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  return (
    <div>
      <h6>Frame Capture</h6>
      <Row>
        <Col xs={6}>
          <OutputPosterCaptureInput tracking={tracking} />
        </Col>
      </Row>
    </div>
  );
}

export default SettingPosterFields;

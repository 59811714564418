import _ from 'lodash-es';
import { selector, selectorFamily, useRecoilCallback } from 'recoil';

import { transformClipOutgoing } from '@api/transform/utils/clips';
import { processKeyframesOutgoing } from '@api/transform/utils/keyframes';

import {
  backgroundAtom,
  cacheAtom,
  callbackAtom,
  clipBoundsAtomFamily,
  clipErrorsAtomFamily,
  clipIdsAtom,
  clipsAtomFamily,
  clipsTracksAtomFamily,
  diskAtom,
  fontIdsAtom,
  fontsAtomFamily,
  overridesAtomFamily,
  soundtrackAtom,
  trackIdsAtom,
} from '@store/Edit';
import { clipKeyframesSelectorFamily } from '@store/Keyframes';
import { mergeDataJsonSelectorFamily, mergeFamily, mergeIdsAtom, mergeReplacementsSelector } from '@store/Merge';
import { outputSelectorFamily } from '@store/Output';
import { FIRST_FRAME_TIME, playheadAtom } from '@store/Timeline';

import DefaultFonts from '@utils/fonts';
import {
  addHandleBars,
  jsonStringifyTemplate,
  removeMetaDataRecursive,
  replaceTextOverrideValues,
} from '@utils/template';

const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$|^\/\/[^\s/$.?#].[^\s]*$/i;
const filterURL = (url) => urlRegex.test(url);

// -------------------------------------------------------------------------------------------------

const clipUpdateFunctionMap = {
  media: (prevState, update) => {
    const { ['asset:meta']: { source, proxied } = {} } = prevState || {};
    const newClip = { ...prevState, ...update };

    if (update?.['asset:src'] && source && proxied) {
      newClip['asset:meta'] = {
        ...(newClip?.['asset:meta'] || {}),
        source: update['asset:src'],
        proxied: false,
      };
    }

    return newClip;
  },
  shape: (prevState, update) => {
    const { ['asset:shape']: shape } = prevState;
    const newState = { ...prevState, ...update };

    const updateDependentProps = () => {
      const strokeWidth = newState['asset:stroke:width'] || 0;

      if (shape === 'rectangle') {
        if (update['asset:width'] && update['asset:height']) {
          const assetWidth = newState['asset:width'] || prevState['asset:width'];
          const assetHeight = newState['asset:height'] || prevState['asset:height'];
          newState['asset:rectangle:width'] = Math.max(0, assetWidth - strokeWidth * 2);
          newState['asset:rectangle:height'] = Math.max(0, assetHeight - strokeWidth * 2);
          newState['asset:width'] = assetWidth;
          newState['asset:height'] = assetHeight;
        } else if (update['asset:rectangle:width'] || update['asset:rectangle:height']) {
          const width = update['asset:rectangle:width'] || prevState['asset:rectangle:width'];
          const height = update['asset:rectangle:height'] || prevState['asset:rectangle:height'];
          newState['asset:width'] = width + strokeWidth * 2;
          newState['asset:height'] = height + strokeWidth * 2;
        }
      } else if (shape === 'circle') {
        if (update['asset:width'] && update['asset:height']) {
          const diameter =
            Math.min(
              newState['asset:width'] || prevState['asset:width'],
              newState['asset:height'] || prevState['asset:height']
            ) - strokeWidth;
          newState['asset:circle:radius'] = diameter / 2;
        } else if ('asset:circle:radius' in update) {
          const radius = Math.max(0, update['asset:circle:radius']);
          newState['asset:width'] = radius * 2;
          newState['asset:height'] = radius * 2;
          newState['asset:circle:radius'] = radius;
        }
      } else if (shape === 'line') {
        let lineLength = newState['asset:width'] || prevState['asset:width'];
        let lineThickness = newState['asset:height'] || prevState['asset:height'] || 0;

        if ('asset:line:length' in update) {
          lineLength = update['asset:line:length'];
        } else if ('asset:width' in update) {
          lineLength = update['asset:width'];
        }

        if ('asset:line:thickness' in update) {
          lineThickness = Math.min(20, Math.max(0, update['asset:line:thickness']));
        } else if ('asset:height' in update) {
          lineThickness = Math.min(20, Math.max(0, update['asset:height']));
        }

        newState['asset:width'] = lineLength;
        newState['asset:height'] = lineThickness;
        newState['asset:line:length'] = lineLength;
        newState['asset:line:thickness'] = lineThickness;
      }
    };

    updateDependentProps();

    return newState;
  },
};

const processClipUpdate = (update) => (prevState) => {
  const { ['asset:src']: src, ['asset:type']: type } = prevState;

  const updateFunction = !!src ? clipUpdateFunctionMap.media : clipUpdateFunctionMap[type];
  if (updateFunction) {
    return updateFunction(prevState, update);
  }

  return { ...prevState, ...update };
};

// -------------------------------------------------------------------------------------------------

export const clipCanvasSelectorFamily = selectorFamily({
  key: 'clip/canvas/selector',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsAtomFamily(clipId));
      const assetTypes = ['html', 'text', 'text-to-speech', 'text-to-image', 'image-to-video'];

      if (assetTypes.includes(clip['asset:type'])) {
        const replacements = get(mergeReplacementsSelector);
        const textDisplay = replaceTextOverrideValues(clip['asset:meta']?.text, replacements);

        let textKey = 'asset:text';
        if (['text-to-image', 'image-to-video'].includes(clip['asset:type'])) {
          textKey = 'asset:prompt';
        }

        return { fit: 'none', scale: 1, ...clip, [textKey]: textDisplay };
      }

      const newClip = { ...clip };

      if (clip?.['asset:src'] && typeof clip['asset:src'] === 'string' && !clip['asset:src'].startsWith('alias://')) {
        try {
          const versionedSrc = new URL(clip['asset:src']);
          const isSignedUrl =
            versionedSrc.search.includes('X-Amz-Signature') || // AWS S3, Cloudflare R2, DO Spaces
            versionedSrc.search.includes('X-Goog-Signature') || // Google Cloud Storage
            versionedSrc.search.includes('sig='); // Azure Blob Storage

          if (!isSignedUrl) {
            versionedSrc.searchParams.set('sscache', 2);
            newClip['asset:src'] = versionedSrc.href;
          } else {
            newClip['asset:src'] = clip['asset:src'];
          }
        } catch (error) {
          console.error('Error updating clip src:', error);
        }
      }

      return newClip;
    },

  set:
    (clipId) =>
    ({ set }, update) => {
      set(clipsAtomFamily(clipId), processClipUpdate(update));
    },
});

export const clipSettingsSelectorFamily = selectorFamily({
  key: 'clip/settings/selector',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsAtomFamily(clipId));
      let sourceClip = null;
      let linkedClips = [];

      if (clip?.['asset:src']?.startsWith('alias://')) {
        const aliasString = clip['asset:src'].replace('alias://', '');
        const clipIds = get(clipIdsAtom);
        sourceClip = clipIds.map((id) => get(clipsAtomFamily(id))).find((c) => c?.alias === aliasString);
      }

      if (clip?.alias) {
        const clipIds = get(clipIdsAtom);
        linkedClips = clipIds
          .map((id) => get(clipsAtomFamily(id)))
          .filter((c) => c?.['asset:src'] === `alias://${clip.alias}`);
      }

      return {
        ...clip,
        links: {
          sourceClip,
          linkedClips,
        },
      };
    },

  set:
    (clipId) =>
    ({ get, set }, update) => {
      const overrides = get(overridesAtomFamily(clipId));
      const mergeIds = get(mergeIdsAtom);
      const mergeFields = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));
      if (_.size(overrides) > 0 && _.size(mergeFields) > 0) {
        // Update merge field value
        _.chain(overrides)
          .pickBy((value, key) => _.has(update, key))
          .forEach((overrideKey, key) => {
            const mergeField = _.find(mergeFields, ({ find }) => find === overrideKey);
            if (mergeField && mergeField.id) {
              set(mergeFamily(mergeField.id), (prevState) => ({
                ...prevState,
                replace: update[key],
              }));
            } else {
              console.warn('No merge field found for override:', overrideKey);
            }
          })
          .value();
      }

      set(clipsAtomFamily(clipId), processClipUpdate(update));
    },
});

export const clipTimelineSelectorFamily = selectorFamily({
  key: 'clip/timeline/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipsAtomFamily(clipId));
      const clipIds = get(clipIdsAtom);
      const clips = clipIds.map((id) => get(clipsAtomFamily(id))).filter((c) => c['asset:type'] !== 'mask');

      if (clip?.meta?.length === 'end') {
        const timelineDuration = get(timelineDurationSelector);
        return {
          ...clip,
          length: clips.length === 1 ? 5 : timelineDuration - Math.max(clip.start, 0),
        };
      }

      return clip;
    },

  set:
    (clipId) =>
    ({ get, set }, update) => {
      const { prop, clip } = update;
      const clipTrackId = get(clipsTracksAtomFamily(clipId)).trackId;
      const clipState = get(clipsAtomFamily(clipId));
      const trackClips = [...get(trackClipsSelectorFamily(clipTrackId))]
        .filter((c) => (clipState['asset:type'] === 'mask' ? c['asset:type'] === 'mask' : c['asset:type'] !== 'mask'))
        .sort((a, b) => a.start - b.start);
      const currentClipIndex = trackClips.findIndex((c) => c.id === clipId);

      set(clipsAtomFamily(clipId), (prevState) => {
        if (prop === 'start') {
          if (prevState?.meta?.start === 'auto') {
            return { ...prevState, ...clip, meta: { ...prevState.meta, start: 'number' } };
          }

          // when adjusting start, make sure it doesn't overlap the previous clip start+length
          const previousClip = trackClips[currentClipIndex - 1];
          if (previousClip && previousClip.start + previousClip.length >= clip.start) {
            return prevState;
          }
        }

        if (prop === 'length') {
          if (prevState?.meta?.length === 'auto') {
            return { ...prevState, ...clip, meta: { ...prevState.meta, length: 'number' } };
          }
          if (prevState?.meta?.length === 'end') {
            const timelineDuration = get(timelineDurationSelector);
            return {
              ...prevState,
              ...clip,
              length: timelineDuration - Math.max(prevState.start, 0),
              meta: { ...prevState.meta, length: 'number' },
            };
          }

          // when adjusting length, make sure it doesn't overlap the next clip start time
          const nextClip = trackClips[currentClipIndex + 1];
          if (nextClip && nextClip?.meta?.start !== 'auto' && prevState.start + clip.length >= nextClip.start) {
            return prevState;
          }
        }

        return { ...prevState, ...clip };
      });

      if (_.size(trackClips) > 1) {
        // Update track clips following this clip WHILE clip.meta.start === auto
        const restTrackClips = trackClips.slice(currentClipIndex + 1);
        let prevClipEnd = clipState.start + clipState.length;
        _.chain(restTrackClips)
          .takeWhile((clip) => clip?.meta?.start === 'auto')
          .forEach((clip) => {
            set(clipsAtomFamily(clip.id), () => ({
              ...clip,
              start: prevClipEnd,
            }));
            prevClipEnd += clip.length;
          })
          .value();
      }
    },
});

export const clipVisibilitySelectorFamily = selectorFamily({
  key: 'clip/visibility/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (clipId) =>
    ({ get }) => {
      const clip = get(clipCanvasSelectorFamily(clipId));
      const playhead = get(playheadAtom);
      const start = typeof clip.start === 'string' ? parseFloat(clip.start) : clip.start;
      const length = typeof clip.length === 'string' ? parseFloat(clip.length) : clip.length;

      if (playhead <= FIRST_FRAME_TIME) {
        return start <= FIRST_FRAME_TIME;
      }

      return playhead >= start && playhead <= start + length;
    },
});

export const clipTrackSelectorFamily = selectorFamily({
  key: 'clip/track/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (clipId) =>
    ({ get }) =>
      get(clipsTracksAtomFamily(clipId)),
});

export const trackClipIdsSelectorFamily = selectorFamily({
  key: 'track/clipIds/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (trackId) =>
    ({ get }) => {
      const clipIds = get(clipIdsAtom);
      return clipIds
        .map((clipId) => {
          const { trackId: clipTrackId } = get(clipsTracksAtomFamily(clipId));
          return clipTrackId === trackId ? clipId : undefined;
        })
        .filter(Boolean);
    },
});

export const trackClipsSelectorFamily = selectorFamily({
  key: 'track/clips/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (trackId) =>
    ({ get }) => {
      const clipIds = get(clipIdsAtom);
      return clipIds
        .filter((clipId) => {
          const { trackId: clipTrackId } = get(clipsTracksAtomFamily(clipId));
          return clipTrackId === trackId;
        })
        .map((clipId) => {
          return get(clipsAtomFamily(clipId));
        });
    },
});

export const otherCanvasBoundsSelectorFamily = selectorFamily({
  key: 'otherCanvasBounds',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (id) =>
    ({ get }) => {
      const clipIds = get(clipIdsAtom);
      return clipIds
        .map((clipId) => {
          const bounds = get(clipBoundsAtomFamily(clipId));
          const visible = get(clipVisibilitySelectorFamily(clipId));

          if (clipId === id || !bounds || !visible) return null;

          return {
            id: clipId,
            bounds: bounds,
          };
        })
        .filter(Boolean);
    },
});

export const googleFontsSelector = selector({
  key: 'googleFontsSelector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get: async () => {
    try {
      const response = await fetch(
        'https://raw.githubusercontent.com/fontsource/google-font-metadata/refs/heads/main/data/google-fonts-v1.json'
      );
      const data = await response.json();
      return Object.values(data)
        .map((font) => {
          const value = font.variants?.['400']?.normal?.latin?.url?.truetype;
          return value ? { value, label: font.family, family: font.family } : null;
        })
        .filter(Boolean);
    } catch (error) {
      console.error('Failed to fetch Google Fonts:', error);
      return [];
    }
  },
});

export const assetListSelector = selector({
  key: 'template/assets/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get: ({ get }) => {
    const soundtrack = get(soundtrackAtom);
    const clipIds = get(clipIdsAtom);
    const fontIds = get(fontIdsAtom);

    const assetsList = clipIds
      .map((clipId) => {
        const { ['asset:src']: src } = get(clipCanvasSelectorFamily(clipId));
        return src;
      })
      .filter(Boolean);

    const customFontsList = fontIds
      .map((fontId) => {
        const { src } = get(fontsAtomFamily(fontId));
        return src;
      })
      .filter(Boolean);

    const defaultFontsList = DefaultFonts.map((font) => font.src).filter(Boolean);

    const mediaList = [...new Set([...assetsList, soundtrack?.src].filter(Boolean))].filter(filterURL);

    const fontsList = [...new Set([...defaultFontsList, ...customFontsList])].filter(filterURL);

    return {
      media: mediaList,
      fonts: fontsList,
    };
  },
});

export const fontListAllSelector = selector({
  key: 'template/fonts/list/all/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get: ({ get }) => {
    const fonts = get(fontIdsAtom);

    const fontsList = fonts.map((font) => {
      return font.src || null;
    });

    return fontsList;
  },
});

export const fontListByCategorySelector = selector({
  key: 'template/fonts/list/category/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get: async ({ get }) => {
    const fontIds = get(fontIdsAtom);
    const googleFonts = await get(googleFontsSelector);
    const fonts = fontIds
      .map((id) => ({ key: id, ...get(fontsAtomFamily(id)) }))
      .filter(({ src, family }) => Boolean(src) && Boolean(family) && !src?.startsWith('https://fonts.gstatic.com/'));
    return { uploaded: fonts, default: DefaultFonts, google: googleFonts };
  },
});

export const timelineDurationSelector = selector({
  key: 'timeline/duration/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get: ({ get }) => {
    const clipIds = get(clipIdsAtom);
    const outPoints = clipIds
      .map((clipId) => get(clipsAtomFamily(clipId)))
      .filter((clip) => clip?.meta?.length !== 'end')
      .map((clip) => {
        const start = typeof clip.start === 'string' ? parseFloat(clip.start) : clip.start;
        const length = typeof clip.length === 'string' ? parseFloat(clip.length) : clip.length;
        return start + length;
      })
      .filter((val) => !Number.isNaN(val));

    const duration = Math.max(...outPoints);
    if (!Number.isFinite(duration)) {
      return 0.0;
    }

    return duration;
  },
});

export const timelineClipTimesSelector = selector({
  key: 'timeline/clip-times/selector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get: ({ get }) => {
    const clipIds = get(clipIdsAtom);

    const points = _.uniq(
      clipIds
        .map((clipId) => {
          const { start, length } = get(clipsAtomFamily(clipId));
          return [start, start + length];
        })
        .flat()
        .filter((val) => !Number.isNaN(val))
    ).sort((a, b) => a - b);

    return points;
  },
});

export const aliasedClipsSelectorFamily = selectorFamily({
  key: 'template/clips/aliased/seletor',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (clipId) =>
    ({ get }) => {
      const clipIds = get(clipIdsAtom);
      return clipIds
        .map((id) => {
          const clip = get(clipsAtomFamily(id));
          return clip.alias ? { id, ...clip } : null;
        })
        .filter((clip) => clip?.id !== clipId)
        .filter(Boolean);
    },
});

export const clipErrorsSelectorFamily = selectorFamily({
  key: 'edit/studio/clip/errors/selectorFamily',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (clipId) =>
    ({ get }) => {
      const errors = get(clipErrorsAtomFamily(clipId));
      return errors;
    },
  set:
    (clipId) =>
    ({ set }, update) => {
      set(clipErrorsAtomFamily(clipId), (prevState) => {
        return [...(prevState || []), ...update];
      });
    },
});

// Todo:
// changing this in to a callback is challenging as there are deps on the state that are not available in the callback.
// Is fired when the state changes and tracks a LOT of changes. Need to find a way to minimise firing when changes occur.
// Most-recent eviction is fine, but there are a lot of updates.
export const derivedJsonSelectorFamily = selectorFamily({
  key: 'template/json/derived',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (debug = false) =>
    ({ get }) => {
      const background = get(backgroundAtom);
      const soundtrackRaw = get(soundtrackAtom);
      const callbackRaw = get(callbackAtom);
      const disk = get(diskAtom);
      const cache = get(cacheAtom);
      const fontIds = get(fontIdsAtom);
      const output = get(outputSelectorFamily(debug));
      const trackIds = get(trackIdsAtom);
      const clipIds = get(clipIdsAtom);

      const callbackOverrides = get(overridesAtomFamily('callback'));
      const callback = {
        ...callbackRaw,
        ...(!debug ? addHandleBars(callbackOverrides) : { callbackOverrides }),
      };

      const soundtrackOverrides = get(overridesAtomFamily('soundtrack'));
      const soundtrack = {
        ...soundtrackRaw,
        ...(!debug ? addHandleBars(soundtrackOverrides) : { soundtrackOverrides }),
      };
      const tracks = clipIds
        .reduce(
          (acc, clipId) => {
            const { trackIndex } = get(clipsTracksAtomFamily(clipId));
            // todo: figure out what type is doing here, we can probably remove it and make sure clip['asset:type'] is used instead
            const { type, ...clipData } = get(clipsAtomFamily(clipId));
            const overrides = get(overridesAtomFamily(clipId));
            const keyframes = get(clipKeyframesSelectorFamily(clipId));
            const clipKeyframesData = processKeyframesOutgoing(keyframes);

            const clipEntry = {
              ...clipData,
              ...clipKeyframesData,
              start: clipData?.meta?.start === 'number' ? clipData?.start : clipData?.meta?.start,
              length: clipData?.meta?.length === 'number' ? clipData?.length : clipData?.meta?.length,
              ...(!debug ? addHandleBars(overrides) : { overrides }),
            };

            const clip = debug ? clipEntry : transformClipOutgoing(clipEntry);

            if (!acc[trackIndex]) {
              acc[trackIndex] = { clips: [] };
            }
            acc[trackIndex].clips.push(clip);
            return acc;
          },
          trackIds.map(() => ({ clips: [] }))
        )
        .filter((track) => track.clips.length > 0);

      let fonts = fontIds.map((fontId) => get(fontsAtomFamily(fontId)));
      if (!debug) {
        fonts = fontIds
          .map((fontId) => {
            const { src, meta } = get(fontsAtomFamily(fontId));
            return meta?.src ?? src;
          })
          .filter(Boolean)
          .map((src) => ({ src }));
      }

      return jsonStringifyTemplate({
        background,
        soundtrack: !debug ? removeMetaDataRecursive(soundtrack) : soundtrack,
        merge: get(mergeDataJsonSelectorFamily(debug)),
        callback: !debug ? callback?.src || '' : callback,
        disk,
        cache,
        fonts,
        output,
        tracks,
      });
    },
});

const updateClipTimingPropertyCallback = (id) => (callbackArgs) => {
  const { snapshot, set } = callbackArgs;
  return (prop, timing) => {
    const clip = snapshot.getLoadable(clipsAtomFamily(id)).contents;
    const clipTrackId = snapshot.getLoadable(clipsTracksAtomFamily(id)).contents.trackId;
    const trackClips = [...snapshot.getLoadable(trackClipsSelectorFamily(clipTrackId)).contents]
      .filter((c) => c['asset:type'] !== 'mask')
      .sort((a, b) => a.start - b.start);
    const timelineDuration = snapshot.getLoadable(timelineDurationSelector).contents;

    let options = ['number'];
    if (prop === 'start' || clip['asset:type'] !== 'mask') {
      options.push('auto');
    }
    if (prop === 'length' && trackClips.length === 1) {
      options.push('end');
    }

    set(clipsAtomFamily(id), (state) => {
      const previousTiming = state.meta[prop];
      const nextValueIndex = (options.indexOf(previousTiming) + 1) % options.length;
      const nextTiming = timing || options[nextValueIndex];

      let values = {
        start: state.start,
        length: state.length,
        meta: {
          start: state.meta.start,
          length: state.meta.length,
        },
      };

      if (prop === 'start') {
        if (nextTiming === 'auto') {
          const currentClipIndex = trackClips.findIndex((c) => c.id === id);
          const previousClip = trackClips[currentClipIndex - 1];
          values.start = previousClip ? previousClip.start + previousClip.length : 0;
          if (state.meta.length === 'end') {
            values.length = timelineDuration - Math.max(values.start, 0);
          }
        } else if (nextTiming === 'number') {
          values.start = state.start;
        }
      }

      if (prop === 'length') {
        if (nextTiming === 'end') {
          values.length = timelineDuration - Math.max(values.start, 0);
        } else if (nextTiming === 'auto' || previousTiming === 'end') {
          const clip = snapshot.getLoadable(clipCanvasSelectorFamily(id)).contents;
          values.length = clip['asset:meta']?.duration || 3;

          if (_.size(trackClips) > 1) {
            const currentClipIndex = trackClips.findIndex((c) => c.id === id);
            const restTrackClips = trackClips.slice(currentClipIndex + 1);

            let prevClipEnd = state.start + values.length;
            _.chain(restTrackClips)
              .forEach((clip) => {
                set(clipsAtomFamily(clip.id), (prevState) => {
                  if (prevClipEnd <= clip.start) {
                    return prevState;
                  }
                  const updatedClip = {
                    ...prevState,
                    start: prevClipEnd,
                  };
                  prevClipEnd = updatedClip.start + updatedClip.length;
                  return updatedClip;
                });
              })
              .value();
          }
        } else if (nextTiming === 'number') {
          values.length = state.length;
        }
      }

      return {
        ...state,
        ...values,
        meta: {
          ...values.meta,
          [prop]: nextTiming,
        },
      };
    });
  };
};

export const useUpdateClipTimingProperty = (id) => useRecoilCallback(updateClipTimingPropertyCallback(id));

export const imageFormats = ['png', 'jpg', 'gif', 'webp'];
export const videoFormats = ['mp4', 'mov', 'webm', 'avi', 'mkv', 'ogv', 'avif'];
export const audioFormats = ['mp3', 'wav', 'wmv'];

export default [
  { value: 'mp4', text: 'mp4' },
  { value: 'gif', text: 'gif' },
  { value: 'jpg', text: 'jpg' },
  { value: 'png', text: 'png' },
];

import { Scrollbar } from 'react-scrollbars-custom';
import { useRecoilValue } from 'recoil';

import SettingBackground from '@feature/studio/setting/SettingBackground';
import SettingCache from '@feature/studio/setting/SettingCache';
import SettingOutput from '@feature/studio/setting/SettingOutput';
import SettingPosterFields from '@feature/studio/setting/SettingPosterFields';
import SettingSoundtrack from '@feature/studio/setting/SettingSoundtrack';
import SettingThumbnailFields from '@feature/studio/setting/SettingThumbnailFields';

import { sdkOptionsSelector } from '@store/Sdk';

function PanelSettingsContainer() {
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);
  return (
    <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
      <div className="workspace__panel-inner settings">
        <SettingBackground />
        <SettingOutput />
        {!isSdkEnabled && (
          <>
            <SettingSoundtrack />
            <SettingThumbnailFields />
            <SettingPosterFields />
            <SettingCache />
          </>
        )}
      </div>
    </Scrollbar>
  );
}

export default PanelSettingsContainer;

import { useEffect } from 'react';
import { Row } from 'react-bootstrap';

import ListItem from '@feature/integrations/IntegrationsListItem';

import { useAnalytics } from '@components/analytics/Analytics';
import Content from '@components/content/Content';
import Layout from '@components/layout/Layout';

import { providers } from '@constants/IntegrationProviders';

function Integrations() {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent('View Integrations');
  }, []);

  return (
    <Layout className="integrations">
      <div className="text-prose mb-16">
        <p className="uppercase-label">3rd party services and integrations</p>
        <h1>Integrations</h1>
      </div>

      <Content
        heading="DESTINATIONS"
        summary={<>Integrate with hosting providers and social media platforms to host and distribute your videos.</>}
      >
        <Row className="mb-4">
          {providers?.destinations.map((props) => (
            <ListItem key={props.provider} {...props} />
          ))}
        </Row>
      </Content>
      <Content
        heading="NO CODE"
        summary="Integrate Shotstack and media automation in to your applications and workflows using no-code tools."
      >
        <Row className="mb-4">
          {providers?.nocode.map((props) => (
            <ListItem key={props.provider} {...props} />
          ))}
        </Row>
      </Content>
      <Content heading="TOOLS" summary="Miscellaneous tools that speed up your workflow.">
        <Row className="mb-4">
          {providers?.tools.map((props) => (
            <ListItem key={props.provider} {...props} />
          ))}
        </Row>
      </Content>
    </Layout>
  );
}

export default Integrations;

import { throttle } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import camelCase from '@utils/text/camelCase';

function InputRange({
  id,
  label,
  title,
  value,
  name,
  handleBlur,
  handleChange,
  min,
  max,
  showLabel = true,
  step = 1,
  prefix,
  suffix,
  disabled = false,
  Action,
}) {
  const inputId = id || camelCase(label);
  const [rangeValue, setRangeValue] = useState(value ?? 1);
  const valueRef = useRef(value);

  useEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value;
      setRangeValue(Number(value) ?? 1);
    }
  }, [value]);

  const throttledHandleChange = useCallback(
    throttle((event) => {
      const newValue = event.target.value;
      setRangeValue(newValue);
      if (handleChange) {
        handleChange(event);
      }
    }, 200),
    [handleChange]
  );

  const handleInternalChange = useCallback(
    (event) => {
      throttledHandleChange(event);
    },
    [throttledHandleChange]
  );

  return (
    <Form.Group controlId={inputId} className="form-group range">
      {(label || Action) && (
        <div className="d-flex align-items-center justify-content-between">
          <div>{showLabel === true && <Form.Label>{label}</Form.Label>}</div>
          <div>{Action || null}</div>
        </div>
      )}
      <InputGroup className="flex-nowrap">
        {prefix && (
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${inputId}`}>{title}</Tooltip>}>
            <InputGroup.Text className="prefix">{prefix}</InputGroup.Text>
          </OverlayTrigger>
        )}
        <div className="slider flex-grow-1 px-1">
          <Form.Range
            name={name}
            value={rangeValue}
            onBlur={handleBlur}
            onChange={handleInternalChange}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
          />
        </div>
        <InputGroup.Text className="suffix">
          {rangeValue}
          {suffix}
        </InputGroup.Text>
      </InputGroup>
    </Form.Group>
  );
}

export default InputRange;

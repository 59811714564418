import { useCallback, useMemo } from 'react';
import reactable from 'reactablejs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import '@css/TimelineClip.css';

import { useBatching } from '@context/RecoilUndo';

import TimelineClip from '@feature/studio/timeline/TimelineClip';

import { activeClipAtom, activeTrackAtom } from '@store/Clips';
import { isKeyframesTabSelector } from '@store/Edit';
import { clipTimelineSelectorFamily, clipTrackSelectorFamily } from '@store/EditSelectors';
import { timelineScaleAtom, toggleTrackMaskVisibleSelectorFamily, trackLockedAtomFamily } from '@store/Timeline';
import { studioActiveTabAtom, studioActiveTabHoverAtom } from '@store/UI';

import { TRACK_HEIGHT, TRACK_PADDING } from '@constants/Timeline';

import getPixelSeconds from '@utils/getPixelSeconds';
import getTransformStyleValues from '@utils/getTransformStyleValues';
import { isNumber } from '@utils/isNumber';
import roundToPrecision from '@utils/math/roundToPrecision';
import { getPanelType } from '@utils/studio/sidebar';

import TimelineClipKeyframes from './TimelineClipKeyframes';

const draggableOptions = {
  inertia: false,
  autoScroll: true,
  cursorChecker: () => null,
};

const resizableOptions = {
  edges: { left: '.clip__resize--left', right: '.clip__resize--right', bottom: false, top: false },
  inertia: false,
};

const ReactableClipComponent = reactable(TimelineClip);

const yAxisPixels = (trackIndex) => trackIndex * TRACK_HEIGHT + TRACK_PADDING;

function TimelineClipReactable({ id }) {
  const [clip, setClip] = useRecoilState(clipTimelineSelectorFamily(id));
  const { trackId, trackIndex } = useRecoilValue(clipTrackSelectorFamily(id));
  const [activeClip, setActiveClip] = useRecoilState(activeClipAtom);
  const setActiveTrack = useSetRecoilState(activeTrackAtom);
  const timelineScale = useRecoilValue(timelineScaleAtom);
  const setActiveTab = useSetRecoilState(studioActiveTabAtom);
  const setActiveTabHover = useSetRecoilState(studioActiveTabHoverAtom);
  const { startBatch, endBatch } = useBatching();
  const pixelSeconds = getPixelSeconds(timelineScale);
  const isKeyframesTab = useRecoilValue(isKeyframesTabSelector);
  const isLocked = useRecoilValue(trackLockedAtomFamily(trackId));
  const showMaskClips = useRecoilValue(toggleTrackMaskVisibleSelectorFamily(trackId));

  const active = useMemo(() => activeClip === id, [activeClip, id]);
  const leftEdgeTrimEnabled = useMemo(() => ['video', 'audio'].includes(clip['asset:type']), [clip['asset:type']]);
  const showKeyframesUI = useMemo(() => active && isKeyframesTab, [active, isKeyframesTab]);
  const isInteractable = useMemo(() => {
    if (isLocked) return false;
    const isMaskClip = clip['asset:type'] === 'mask';
    return showMaskClips ? isMaskClip : !isMaskClip;
  }, [isLocked, showMaskClips, clip['asset:type']]);

  const getNewLength = useCallback(
    (length) => {
      const assetDuration = clip['asset:meta']?.duration;
      const newLength = roundToPrecision(length);
      const maxLength = clip['asset:type'] !== 'overlay' && assetDuration ? assetDuration : Infinity;
      const minLength = 0.01;
      if (newLength >= maxLength) {
        return maxLength;
      }
      if (newLength <= minLength) {
        return minLength;
      }
      return newLength;
    },
    [clip['asset:meta']?.duration, clip['asset:type']]
  );

  const handleResizeClipLeft = useCallback(
    ({ dir, delta }) => {
      if (dir === 'left' && clip.start > 0) {
        const newLength = getNewLength(clip.length + delta);
        const lengthDelta = newLength - clip.length;

        setClip({
          prop: 'start',
          clip: {
            start: roundToPrecision(clip.start - lengthDelta),
            length: newLength,
            ...(leftEdgeTrimEnabled
              ? { 'asset:trim': clip['asset:trim'] >= 0 ? roundToPrecision(clip['asset:trim'] - lengthDelta) : 0 }
              : {}),
          },
        });
      } else if (dir === 'right') {
        const newLength = getNewLength(clip.length - delta);
        const lengthDelta = clip.length - newLength;

        setClip({
          prop: 'start',
          clip: {
            start: roundToPrecision(clip.start + lengthDelta),
            length: newLength,
            ...(leftEdgeTrimEnabled
              ? { 'asset:trim': clip['asset:trim'] >= 0 ? roundToPrecision(clip['asset:trim'] + lengthDelta) : 0 }
              : {}),
          },
        });
      }
    },
    [clip['asset:trim'], clip.length, clip.start, getNewLength, leftEdgeTrimEnabled, setClip]
  );

  const handleResizeClipRight = useCallback(
    ({ dir, delta }) => {
      if (dir === 'left') {
        const newLength = getNewLength(clip.length - delta);
        setClip({
          prop: 'length',
          clip: {
            length: newLength,
          },
        });
      } else if (dir === 'right') {
        const newLength = getNewLength(clip.length + delta);
        setClip({
          prop: 'length',
          clip: {
            length: newLength,
          },
        });
      }
    },
    [clip.length, getNewLength, setClip]
  );

  const onResizeMove = useCallback(
    (event) => {
      const { edges, velocity, delta: deltaPixels } = event;
      const { left: leftEdge, right: rightEdge } = edges;
      const dir = velocity.x < 0 ? 'left' : 'right';
      const delta = Math.abs(deltaPixels.x) / timelineScale;

      if (leftEdge) {
        handleResizeClipLeft({ dir, delta });
      } else if (rightEdge) {
        handleResizeClipRight({ dir, delta });
      }
    },
    [timelineScale, handleResizeClipLeft, handleResizeClipRight]
  );

  const onDragMove = useCallback((event) => {
    const { dx, dy, target: draggableElement } = event;
    const { x: clipX, y: clipY } = getTransformStyleValues(draggableElement.style.transform);
    const x = roundToPrecision(clipX + dx);
    const y = roundToPrecision(clipY + dy);

    if (!isNumber(x) || !isNumber(y)) {
      return;
    }

    draggableElement.style.transform = `translate(${x}px, ${y}px)`;
  }, []);

  const onDragEnd = useCallback(
    (event) => {
      const { dx, target: draggableElement } = event;
      const { x: clipX } = getTransformStyleValues(draggableElement.style.transform);
      const x = roundToPrecision(clipX + dx);
      const y = yAxisPixels(trackIndex);

      if (!isNumber(x) || !isNumber(y)) {
        return;
      }

      draggableElement.style.transform = `translate(${x}px, ${y}px)`;
    },
    [clip, trackIndex]
  );

  const onDown = useCallback(
    (event) => {
      startBatch();

      event.preventDefault();
      setActiveClip(id);
      setActiveTrack(trackId);
      setActiveTab(getPanelType(clip['asset:type']));
      setActiveTabHover(null);
    },
    [id, setActiveClip, setActiveTrack, getPanelType, startBatch]
  );

  const onUp = useCallback(() => {
    endBatch();
  }, [endBatch]);

  if (showKeyframesUI) {
    return (
      <TimelineClipKeyframes
        id={id}
        clip={clip}
        x={pixelSeconds(clip.start)}
        y={yAxisPixels(trackIndex)}
        width={pixelSeconds(clip.length)}
      />
    );
  }

  if (!isInteractable) {
    return (
      <TimelineClip
        id={id}
        clip={clip}
        trackId={trackId}
        active={active}
        x={pixelSeconds(clip.start)}
        y={yAxisPixels(trackIndex)}
        width={pixelSeconds(clip.length)}
        maxWidth={pixelSeconds(clip['asset:meta']?.duration)}
      />
    );
  }

  return (
    <ReactableClipComponent
      draggable={draggableOptions}
      resizable={resizableOptions}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      onResizeMove={onResizeMove}
      onDown={onDown}
      onUp={onUp}
      id={id}
      clip={clip}
      trackId={trackId}
      active={active}
      x={pixelSeconds(clip.start)}
      y={yAxisPixels(trackIndex)}
      width={pixelSeconds(clip.length)}
      maxWidth={pixelSeconds(clip['asset:meta']?.duration)}
    />
  );
}

export default TimelineClipReactable;

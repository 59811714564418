export const helloWorldVideoTemplate = {
  timeline: {
    tracks: [
      {
        clips: [
          {
            asset: {
              type: 'text',
              text: 'Welcome to Shotstack',
              font: {
                family: 'Clear Sans',
                color: '#ffffff',
                size: 46,
              },
              alignment: {
                horizontal: 'left',
              },
              width: 566,
              height: 70,
            },
            start: 4,
            length: 'end',
            transition: {
              in: 'fade',
              out: 'fade',
            },
            offset: {
              x: -0.15,
              y: 0,
            },
            effect: 'zoomIn',
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'video',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/footage/earth.mp4',
              trim: 5,
              volume: 1,
            },
            start: 0,
            length: 'auto',
            transition: {
              in: 'fade',
              out: 'fade',
            },
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'audio',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/music/freepd/motions.mp3',
              effect: 'fadeOut',
              volume: 1,
            },
            start: 0,
            length: 'end',
          },
        ],
      },
    ],
  },
  output: {
    format: 'mp4',
    size: {
      width: 1280,
      height: 720,
    },
  },
};

export const helloWorldImageTemplate = {
  timeline: {
    background: '#FFFFFF',
    tracks: [
      {
        clips: [
          {
            asset: {
              type: 'text',
              text: 'Welcome to',
              font: {
                family: 'Montserrat SemiBold',
                color: '#000000',
                size: 18,
              },
              alignment: {
                horizontal: 'center',
              },
              width: 134,
              height: 36,
            },
            start: 'auto',
            offset: {
              x: -0.28,
              y: 0.058,
            },
            length: 'auto',
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'text',
              text: 'Shotstack',
              font: {
                family: 'Montserrat ExtraBold',
                color: '#25d3d0',
                size: 38,
              },
              alignment: {
                horizontal: 'center',
              },
              width: 279,
              height: 46,
            },
            start: 'auto',
            offset: {
              x: -0.28,
              y: 0.01,
            },
            length: 'auto',
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'image',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/images/earth.jpg',
            },
            start: 'auto',
            length: 'auto',
            position: 'center',
            offset: {
              x: 0.4,
              y: 0,
            },
          },
        ],
      },
    ],
  },
  output: {
    format: 'jpg',
    size: {
      width: 720,
      height: 720,
    },
  },
};

export const helloWorldGifTemplate = {
  timeline: {
    tracks: [
      {
        clips: [
          {
            asset: {
              type: 'text',
              text: 'Welcome to Shotstack',
              font: {
                family: 'Clear Sans',
                color: '#ffffff',
                size: 46,
              },
              alignment: {
                horizontal: 'left',
              },
              width: 566,
              height: 70,
            },
            start: 4,
            length: 'end',
            transition: {
              in: 'fade',
              out: 'fade',
            },
            offset: {
              x: -0.15,
              y: 0,
            },
            effect: 'zoomIn',
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'video',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/footage/earth.mp4',
              trim: 5,
              volume: 1,
            },
            start: 0,
            length: 'auto',
            transition: {
              in: 'fade',
              out: 'fade',
            },
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'audio',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/music/freepd/motions.mp3',
              effect: 'fadeOut',
              volume: 1,
            },
            start: 0,
            length: 'end',
          },
        ],
      },
    ],
  },
  output: {
    format: 'gif',
    resolution: 'hd',
    scaleTo: 'mobile',
    quality: 'low',
    fps: 12,
  },
};

export const helloWorldMergeFieldTemplate = {
  merge: [
    {
      find: 'NAME',
      replace: 'ALICE',
    },
  ],
  timeline: {
    tracks: [
      {
        clips: [
          {
            asset: {
              type: 'text',
              text: 'HELLO ALICE',
              font: {
                family: 'Clear Sans',
                color: '#ffffff',
                size: 46,
              },
              alignment: {
                horizontal: 'center',
              },
              width: 410,
              height: 100,
            },
            start: 4,
            length: 'end',
            transition: {
              in: 'fade',
              out: 'fade',
            },
            offset: {
              x: -0.3,
            },
            effect: 'zoomIn',
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'video',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/footage/earth.mp4',
              trim: 5,
            },
            start: 'auto',
            length: 'auto',
            transition: {
              in: 'fade',
              out: 'fade',
            },
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'audio',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/music/freepd/motions.mp3',
              effect: 'fadeOut',
            },
            start: 0,
            length: 'end',
          },
        ],
      },
    ],
  },
  output: {
    format: 'mp4',
    size: {
      width: 1280,
      height: 720,
    },
  },
};

export const createATemplate = {
  name: 'Hello World Template',
  template: {
    merge: [
      {
        find: 'NAME',
        replace: 'A L I C E',
      },
    ],
    timeline: {
      background: '#000000',
      tracks: [
        {
          clips: [
            {
              asset: {
                type: 'html',
                width: 410,
                height: 70,
                html: '<p data-html-type="text">H E L L O &nbsp; {{ NAME }}</p>',
                css: "p { color: #ffffff; font-size: 26px; font-family: 'Clear Sans'; text-align: center; }",
              },
              start: 4,
              length: 'end',
              transition: {
                in: 'fade',
                out: 'fade',
              },
              offset: {
                x: -0.3,
              },
              effect: 'zoomIn',
            },
          ],
        },
        {
          clips: [
            {
              asset: {
                type: 'video',
                src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/footage/earth.mp4',
                trim: 5,
              },
              start: 'auto',
              length: 'auto',
              transition: {
                in: 'fade',
                out: 'fade',
              },
            },
          ],
        },
        {
          clips: [
            {
              asset: {
                type: 'audio',
                src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/music/freepd/motions.mp3',
                effect: 'fadeOut',
              },
              start: 0,
              length: 'end',
            },
          ],
        },
      ],
    },
    output: {
      format: 'mp4',
      size: {
        width: 1280,
        height: 720,
      },
    },
  },
};

export const renderATemplate = {
  id: 'YOUR_TEMPLATE_ID_HERE',
  merge: [
    {
      find: 'NAME',
      replace: 'B O B',
    },
  ],
};

export const videoTextToSpeech = {
  timeline: {
    tracks: [
      {
        clips: [
          {
            asset: {
              type: 'caption',
              src: 'alias://voiceover',
              font: {
                color: '#ffffff',
                family: 'Montserrat ExtraBold',
                size: 30,
                lineHeight: 0.8,
              },
              margin: {
                top: 0.25,
              },
            },
            start: 0,
            length: 'end',
          },
        ],
      },
      {
        clips: [
          {
            alias: 'voiceover',
            asset: {
              type: 'text-to-speech',
              text: 'Good evening, in Sydney tonight we’re tracking a developing story as unexpected storms roll in across the city, bringing with them flash flooding warnings and major disruptions to the evening commute.',
              voice: 'Joanna',
            },
            start: 0,
            length: 'auto',
          },
        ],
      },
    ],
  },
  output: {
    format: 'mp4',
    size: {
      width: 1280,
      height: 720,
    },
  },
};

export const videoTextToImage = {
  timeline: {
    tracks: [
      {
        clips: [
          {
            asset: {
              type: 'text-to-image',
              prompt: 'Landscape of Sydney harbour in bad weather, Editorial Photography, Aerial View, 32k, Optics',
              width: 1280,
              height: 720,
            },
            start: 0,
            length: 5,
          },
        ],
      },
    ],
  },
  output: {
    format: 'mp4',
    size: {
      width: 1280,
      height: 720,
    },
  },
};

export const videoMergeFieldVimeoDestination = {
  output: {
    format: 'mp4',
    size: {
      width: 1280,
      height: 720,
    },
    destinations: [
      {
        provider: 'vimeo',
        options: {
          name: 'My Shotstack Video',
          description: 'This video was created using the Shotstack API',
        },
      },
    ],
  },
  timeline: {
    tracks: [
      {
        clips: [
          {
            asset: {
              type: 'text',
              text: 'Welcome to Shotstack',
              font: {
                family: 'Clear Sans',
                color: '#ffffff',
                size: 46,
              },
              alignment: {
                horizontal: 'left',
              },
              width: 566,
              height: 70,
            },
            start: 4,
            length: 'end',
            transition: {
              in: 'fade',
              out: 'fade',
            },
            offset: {
              x: -0.15,
              y: 0,
            },
            effect: 'zoomIn',
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'video',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/footage/earth.mp4',
              trim: 5,
            },
            start: 'auto',
            length: 'auto',
            transition: {
              in: 'fade',
              out: 'fade',
            },
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'audio',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/music/freepd/motions.mp3',
              effect: 'fadeOut',
            },
            start: 0,
            length: 'end',
          },
        ],
      },
    ],
  },
};

export const imageToVideo = {
  timeline: {
    tracks: [
      {
        clips: [
          {
            length: 'auto',
            asset: {
              type: 'image-to-video',
              src: 'https://shotstack-ingest-api-v1-sources.s3.ap-southeast-2.amazonaws.com/wzr6y0wtti/zzz01jh6-tp5k4-9e22c-dszkp-wa727z/source.jpg',
              prompt: 'Slowly orbit clockwise around the object',
              aspectRatio: '16:9',
            },
            start: 0,
          },
        ],
      },
    ],
  },
  output: {
    format: 'mp4',
    size: {
      width: 1280,
      height: 720,
    },
  },
};

export const videoCallback = {
  callback: 'https://webhook.site/490eb1b2-b9ac-44dd-8822-fced72fd3abc',
  timeline: {
    tracks: [
      {
        clips: [
          {
            asset: {
              type: 'text',
              text: 'Welcome to Shotstack',
              font: {
                family: 'Clear Sans',
                color: '#ffffff',
                size: 46,
              },
              alignment: {
                horizontal: 'left',
              },
              width: 566,
              height: 70,
            },
            start: 4,
            length: 'end',
            transition: {
              in: 'fade',
              out: 'fade',
            },
            offset: {
              x: -0.15,
              y: 0,
            },
            effect: 'zoomIn',
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'video',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/footage/earth.mp4',
              trim: 5,
            },
            start: 0,
            length: 'auto',
            transition: {
              in: 'fade',
              out: 'fade',
            },
          },
        ],
      },
      {
        clips: [
          {
            asset: {
              type: 'audio',
              src: 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/music/freepd/motions.mp3',
              effect: 'fadeOut',
            },
            start: 0,
            length: 'end',
          },
        ],
      },
    ],
  },
  output: {
    format: 'mp4',
    size: {
      width: 1280,
      height: 720,
    },
  },
};

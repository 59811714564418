import { Col, Row } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import OutputFormatSelect from '@feature/studio/setting/output/FormatSelect';
import OutputFrameRateSelect from '@feature/studio/setting/output/FrameRateSelect';
import OutputHeightInput from '@feature/studio/setting/output/HeightInput';
import OutputPresetSelect from '@feature/studio/setting/output/PresetSelect';
import OutputQualitySelect from '@feature/studio/setting/output/QualitySelect';
import OutputScaleToSelect from '@feature/studio/setting/output/ScaleToSelect';
import OutputWidthInput from '@feature/studio/setting/output/WidthInput';

import InputCheckbox from '@components/controls/InputCheckbox';

import { formatAtom, repeatAtom } from '@store/Output';
import { templateIdAtom } from '@store/Template';

function SettingOutput() {
  const [repeat, setRepeat] = useRecoilState(repeatAtom);
  const format = useRecoilValue(formatAtom);
  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  const handleRepeatChange = (event) => {
    const { checked } = event.target;
    setRepeat(checked);
  };

  return (
    <div>
      <div className="mb-10">
        <h6>Dimensions</h6>

        <Row className="mb-2">
          <Col md={12}>
            <OutputPresetSelect tracking={tracking} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={6}>
            <OutputWidthInput />
          </Col>
          <Col md={6}>
            <OutputHeightInput />
          </Col>
        </Row>
      </div>
      <div>
        <h6>Output</h6>
        <Row className="mb-2">
          <Col md={6}>
            <OutputFormatSelect tracking={tracking} />
          </Col>
          <Col md={6}>
            <OutputFrameRateSelect tracking={tracking} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={6}>
            <OutputScaleToSelect tracking={tracking} />
          </Col>
          <Col md={6}>
            <OutputQualitySelect tracking={tracking} />
          </Col>
        </Row>
      </div>
      {format === 'gif' && (
        <Row className="mt-3">
          <Col>
            <InputCheckbox
              label="Repeat GIF"
              checked={repeat}
              handleChange={handleRepeatChange}
              tracking={{
                ...tracking,
                name: 'Check Repeating GIF',
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default SettingOutput;

import { Scrollbar } from 'react-scrollbars-custom';

import PanelMaskUpload from '@feature/studio/panel/PanelMaskUpload';
import PanelMediaListing from '@feature/studio/panel/PanelMediaListing';

function PanelMaskDefault() {
  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <PanelMaskUpload />
        <PanelMediaListing type="mask" />
      </Scrollbar>
    </div>
  );
}

export default PanelMaskDefault;

import { Col, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import {
  IconBorderRadius,
  IconFormatColorFill,
  IconFormatColorReset,
  IconLineWidth,
  IconRadius,
  IconStroke,
  IconStrokeWidth,
  IconTransparent,
} from '@assets/icons';

import OffsetFields from '@feature/studio/fields/OffsetFields';
import TimingFields from '@feature/studio/fields/TimingFields';
import TransformFields from '@feature/studio/fields/TransformFields';
import VisualEffectsFields from '@feature/studio/fields/VisualEffectsFields';

import ColorPicker from '@components/atoms/ColorPicker';
import InputNumber from '@components/controls/InputNumber';
import InputRange from '@components/controls/InputRange';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

const assetPropertyTransform = (prop, type, value) =>
  type === 'number' ? (value ? parseFloat(value) : undefined) : value;

const SettingAssetShape = ({ id }) => {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));

  const handleAssetUpdate =
    ({ prop, type, value }) =>
    (event) => {
      const { type: inputType, value: eventValue } = event.target;
      setClip({ [prop]: assetPropertyTransform(prop, type || inputType, value ?? eventValue) });
    };

  return (
    <>
      <h6>Style</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputGroup>
              <InputGroup.Text className="prefix">
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-fill-color`}>Fill Color</Tooltip>}>
                  <button
                    type="button"
                    name="asset:fill:color"
                    className="unstyled"
                    disabled={!clip['asset:fill:color']}
                    onClick={handleAssetUpdate({ prop: 'asset:fill:color' })}
                  >
                    {clip['asset:fill:color'] ? (
                      <IconFormatColorReset size={18} title="Transparent Fill Color" />
                    ) : (
                      <IconFormatColorFill size={18} title="Fill Color" />
                    )}
                  </button>
                </OverlayTrigger>
              </InputGroup.Text>
              <ColorPicker
                className="color"
                value={clip['asset:fill:color']}
                handleEvent={handleAssetUpdate({ prop: 'asset:fill:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:fill:color" />
          </Col>
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="fill-opacity"
              title="Fill Opacity"
              value={clip['asset:fill:opacity'] >= 0 ? clip['asset:fill:opacity'] : 1}
              placeholder="1"
              min={0}
              max={1}
              step={0.01}
              prefix={<IconTransparent size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:fill:opacity' })}
            />
            <OverrideMenu path="asset:fill:opacity" />
          </Col>
        </Row>
      </div>

      <h6>Stroke</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputGroup>
              <InputGroup.Text className="prefix">
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-stroke-color`}>Stroke Color</Tooltip>}>
                  <button
                    type="button"
                    name="asset:stroke:color"
                    className="unstyled"
                    disabled={!clip['asset:stroke:color']}
                    onClick={handleAssetUpdate({ prop: 'asset:stroke:color' })}
                  >
                    {clip['asset:stroke:color'] ? (
                      <IconFormatColorReset size={18} title="Transparent Stroke Color" />
                    ) : (
                      <IconStroke size={18} title="Stroke Color" />
                    )}
                  </button>
                </OverlayTrigger>
              </InputGroup.Text>
              <ColorPicker
                className="color"
                value={clip['asset:stroke:color']}
                handleEvent={handleAssetUpdate({ prop: 'asset:stroke:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:stroke:color" />
          </Col>
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="stroke-width"
              title="Stroke Width"
              value={clip['asset:stroke:width'] || 0}
              placeholder="0"
              min={0}
              max={20}
              step={1}
              prefix={<IconStrokeWidth size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:stroke:width' })}
            />
            <OverrideMenu path="asset:stroke:width" />
          </Col>
        </Row>
      </div>

      {clip['asset:shape'] === 'rectangle' && (
        <div className="mb-10">
          <h6>Rectangle Properties</h6>
          <Row className="mb-2">
            <Col className="d-flex align-items-center justify-content-between gap-2">
              <InputNumber
                id="rectangle-width"
                title="Width"
                value={clip['asset:rectangle:width']}
                min={0}
                step={1}
                prefix="W"
                handleChange={handleAssetUpdate({ prop: 'asset:rectangle:width' })}
              />
              <OverrideMenu path="asset:rectangle:width" />
            </Col>
            <Col className="d-flex align-items-center justify-content-between gap-2">
              <InputNumber
                id="rectangle-height"
                title="Height"
                value={clip['asset:rectangle:height']}
                min={0}
                step={1}
                prefix="H"
                handleChange={handleAssetUpdate({ prop: 'asset:rectangle:height' })}
              />
              <OverrideMenu path="asset:rectangle:height" />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="d-flex align-items-center justify-content-between gap-2">
              <InputRange
                id="corner-radius"
                title="Corner Radius"
                value={clip['asset:rectangle:cornerRadius'] || 0}
                min={0}
                max={100}
                step={1}
                prefix={<IconBorderRadius size={18} />}
                handleChange={handleAssetUpdate({ prop: 'asset:rectangle:cornerRadius', type: 'number' })}
              />
              <OverrideMenu path="asset:rectangle:cornerRadius" />
            </Col>
          </Row>
        </div>
      )}

      {clip['asset:shape'] === 'circle' && (
        <div className="mb-10">
          <h6>Circle Properties</h6>
          <Row className="mb-2">
            <Col className="d-flex align-items-center justify-content-between gap-2">
              <InputNumber
                id="circle-radius"
                title="Radius"
                value={clip['asset:circle:radius']}
                min={0}
                step={1}
                prefix={<IconRadius size={18} />}
                handleChange={handleAssetUpdate({ prop: 'asset:circle:radius' })}
              />
              <OverrideMenu path="asset:circle:radius" />
            </Col>
          </Row>
        </div>
      )}

      {clip['asset:shape'] === 'line' && (
        <div className="mb-10">
          <h6>Line Properties</h6>
          <Row className="mb-2">
            <Col className="d-flex align-items-center justify-content-between gap-2">
              <InputNumber
                id="line-length"
                title="Length"
                value={clip['asset:line:length']}
                min={0}
                step={1}
                prefix={<IconLineWidth size={18} />}
                suffix="px"
                handleChange={handleAssetUpdate({ prop: 'asset:line:length' })}
              />
              <OverrideMenu path="asset:line:length" />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="d-flex align-items-center justify-content-between gap-2">
              <InputRange
                id="line-thickness"
                title="Thickness"
                value={clip['asset:line:thickness'] || 0}
                placeholder="0"
                min={0}
                max={20}
                step={1}
                prefix={<IconStrokeWidth size={18} />}
                handleChange={handleAssetUpdate({ prop: 'asset:line:thickness' })}
              />
              <OverrideMenu path="asset:line:thickness" />
            </Col>
          </Row>
        </div>
      )}

      <h6>Transform</h6>
      <div className="mb-10">
        <TransformFields id={id} />
      </div>

      <h6>Offset</h6>
      <div className="mb-10">
        <OffsetFields id={id} />
      </div>

      <h6>Visual Effects</h6>
      <div className="mb-10">
        <VisualEffectsFields id={id} />
      </div>

      <h6>Timing</h6>
      <TimingFields id={id} />
    </>
  );
};

export default SettingAssetShape;
